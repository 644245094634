<template>
<div>
<app-menu/>
 <v-container class="grey lighten-5" style="min-height:656px;width:100%;max-width:1850px;" width="100%" max-width="300px">
    <div class="text-center"> 
      <v-dialog v-model="isLoad" persistent width="300">
         <v-card color="primary" dark >
          <v-card-text>
            cargando
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card> 
      </v-dialog>
    </div>
    <v-row class="justify-center"> 
      <v-col cols="12" md="10" class="mx-auto">
        <v-container>
          <v-row>
            <v-col cols="12" sm="1">
              <v-btn icon color="blue" @click="returnOrigen"> <v-icon>mdi-arrow-left</v-icon>volver</v-btn>
            </v-col>
            <v-col cols="12" sm="11" > 
              <v-alert
                v-model="alert2"
                dismissible
                
                color="error"
                border="left"
                type="error"
                elevation="10"
                colored-border
                >{{msgWarning}}</v-alert>
            </v-col>
          </v-row>
        </v-container> 
        <div v-if="productoEncontrado && isLoad == false" style="min-height: 600px;">
          <v-row v-if="agregado==false" no-gutters >
            <v-col >
              <v-card  width="70%" class="mx-auto">  
                <center>   
                  <v-row justify="end" class="fill-height ma-0" v-if="producto.monto_descuento != null">
                    <v-avatar color="#02DE7D" size="70" >
                      <span class="white--text">
                          {{producto.descuento_name}}
                          <div class="white--text" style="font-size: 0.7em;">
                          descuento
                          </div>
                      </span> 
                    </v-avatar>
                  </v-row>
                  <center>  
                    <v-card>
                      <v-card-title>
                        <div style="font-size: 95%;color:black;" class="container my-2">
                          <i>
                            {{producto.name}}
                          </i>
                        </div>  
                      </v-card-title>
                      <v-card-subtitle>
                        <div style="font-size: 15px;" class=""> <strong>{{producto.description}}</strong> </div>
                      </v-card-subtitle>
                    </v-card> 
                    
                      
                  </center>
                  <zoom-on-hover :scale="2" width="400px" :img-normal="`${ubicacionImagenProducto}${producto.value}.jpg`">
                  </zoom-on-hover>  

                </center>
                <!-- <v-card-subtitle class="pb-0 producto_principal">
                    {{producto.description}}
                </v-card-subtitle> -->
                <template>
                  
                  <div  class="text-center">
                    <v-card width="" max-width="100%" elevation="0">
                      <v-row class="justify-center">
                          <v-rating class="ratingCursor"
                            v-model="rating.calificacion"
                            background-color="#6E6F64 lighten-4"
                            color="yellow"
                            half-increments
                          ></v-rating>
                          <div class="ratingUser"   style="font-size:13px;margin-top:10px;">
                            (<v-icon color="blue" style="font-size:13px;">mdi-account</v-icon> / {{calificacionUser.length}})
                          </div>
                      </v-row>
                      <v-btn @click="calificar(user.ad_user_id,producto.value)" small text color="cyan">Calificar</v-btn>
                    </v-card>
                  </div>
                  <div >
                    <v-dialog v-model="panelIngresarComen" width="500" max-width="100%" persistent>
                      <v-card class="container"   elevation="0">
                        <v-card color="cyan" dark style="border-radius: 0px 20px 20px 0px; color:white;">
                          <v-card-title style="font-size:15px;" >AGREGAR COMENTARIO</v-card-title>
                        </v-card>
                          <br>
                          <v-row class="mx-auto">
                            <v-col md="6" >
                              <v-rating style="margin-top:30px"
                                v-model="addCalificacion.calificacion"
                                background-color="#6E6F64 lighten-4"
                                color="yellow"
                                half-increments
                                hover
                                size="25"
                              ></v-rating>
                            </v-col>
                            <v-col md="2">
                              <img class="mt-1" width="150" :src="`${ubicacionImagenProducto}${producto.value}.jpg`"  alt="">
                            </v-col>
                          </v-row>
                          <v-textarea
                          v-model="addCalificacion.opinion"
                          outlined
                          color="cyan"
                          auto-grow
                          rows="2"
                          :rules="[(v)=> !!v || 'El comentario es requerido']"
                          ></v-textarea>
                        
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn @click="panelIngresarComen = false"  color="error" text >Cancelar</v-btn>
                          <v-btn  right v-if="addCalificacion.calificacion >=0.5"  :disabled=" productoYaCalificado == true" text color="cyan" @click="agregCalificacion">Calificar producto</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                  
                  <!-- <v-btn text gradient  color="red" name="calificacion"  @click="calificacionUsuario()">TRAER calificación</v-btn> -->
                </template>
                <!-- <center>   
                  <div style="font-size: 1.3em;color:black;" class=" my-2">{{producto.name}}</div>
                  <div style="font-size: 15px;" class="">{{producto.description}}</div>
                </center> -->
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">DISP.</th>
                        <th class="text-left">PRECIO</th>
                        <th class="text-left">UNIDAD</th>
                        <th class="text-left">CÓDIGO</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{producto.mex_quantytotal}}</td>
                        <td>{{formatMXN(producto.l0)}}</td>
                        <td>{{producto.unidad}}</td>
                        <td>{{producto.value}}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>  
              </v-card>
              
            </v-col>
            
            <v-col >      
              <template> 
                <v-container >
                  <v-row>
                    <v-col class="justify-center"> 
                      <v-row>
                        <v-col md="7" class="mx-auto">
                          <div>Cantidad de la compra   
                            <v-row>
                              <v-col>
                                <center>
                                  <!-- v-on:keyup="qtyMovement('0')"  @click="qtyMovement('0')" -->
                                  <v-text-field class="centered-input text--darken-3 mt-3" style="width:300px" 
                                    v-model="qty" type="number" label="Cantidad"
                                    append-outer-icon="mdi-plus" 
                                    prepend-icon="mdi-minus"
                                    @click:append-outer="qtyMovement('+')" 
                                    @click:prepend="qtyMovement('-')"
                                    @blur="qtyMovement('0')"
                                    onkeydown="javascript: return event.keyCode == 69 ? false : true" 
                                  ></v-text-field>
                                  <p style="font-size: 0.9em; color:#909090">
                                    {{producto.mex_quantytotal}} {{producto.mex_quantytotal == 1 ?"disponible":"disponibles"}}
                                    <!-- <br>{{producto.total}} limite de compra -->
                                  </p>
                                </center> 
                              </v-col>
                            </v-row> 
                          </div>

                          <div v-if="producto.monto_descuento != null">
                            <div class="text-decoration-line-through text-center" style="color: green;margin-bottom:-25px">
                                    Antes {{formatMXN((producto.costo_original))}}
                            </div> <br>
                            <div class="font-black text-center" >
                                    Ahora {{ formatMXN(producto.l0) }} c/u.
                            </div>
                          </div> 
                          <div v-else class="font-black text-center">{{formatMXN(producto.l0)}} c/u.</div>
                          
                          <p class="font-black text-center" style=" font-size: 1.8em;">{{calculaTotalProd}}</p>
                          <center>
                            <div v-if="producto.mex_quantytotal < qty" class="font-weight-thin-black" style="font-size: 0.8em; color:#F72D04"> 
                              <div v-if="((producto.mex_quantytotal - qty)*-1)==1">
                                No hay stock disponible, se agregará 1 pza en forma de pedido.
                              </div>
                              <div v-else>
                                No hay stock disponible, se agregarán <strong>{{(producto.mex_quantytotal - qty)*-1}}</strong> pzas en forma de pedido.
                              </div>
                            </div>  
                          </center>
                          <br>
                          <v-card-actions>
                            <v-btn  small class="mx-auto white--text" width="100%"  color="cyan" @click="addtocar"  >
                              Agregar Al Carrito
                            </v-btn>      
                          </v-card-actions>      
                          <v-alert v-if="msgErro!=''" dense outlined type="error" >
                            {{msgErro}}
                          </v-alert>
    
                        </v-col>
                      </v-row> 
                      <v-row> 
                        <v-col>
                          <v-card class="mx-auto" width="80%"> 
                            <v-card-text>
                              <p class="producto_descripcion_general" style="font-size: 1.4em;"  >Descripción del producto</p> 
                              <v-divider color="cyan" class="ma-5 "></v-divider>
                              <div style="font-size: 1.3em;" class="cont text--primary text-sm-left producto_descripcion_general" v-html="producto.elements"> </div>
                            </v-card-text> 
                          </v-card>
                        </v-col>
                      </v-row>  
                    </v-col> 
                  </v-row>  
                </v-container> 
              </template> 
            </v-col>    
            <v-container max-width="1080" fluid class="pa-0 ma-0 my-10" >
              <v-col>
                  <v-card class="mx-auto" max-width="80%">
                    <v-toolbar color="cyan" dark>
                      <template>
                        <v-tabs v-model="tabs" next-icon="mdi-arrow-right-bold-box-outline" prev-icon="mdi-arrow-left-bold-box-outline"
                          show-arrows fixed-tabs>
                          <v-tab v-for="n in itemstabs" :key="n" class="">
                            {{ n }}
                          </v-tab>
                        </v-tabs>
                      </template>
                    </v-toolbar> 
                    <v-tabs-items v-model="tabs">
                      <v-tab-item>
                        <v-card flat width="400" class="">
                          <v-card-text class="">
                            <i> 
                              <strong>
                                <p v-html="producto.technicalcharacteristics"></p>
                              </strong>
                            </i>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <v-card flat width="400" class="">
                          <v-card-title class="text-h5" >
                            Datos Generales:
                          </v-card-title>
                          <v-card-text class="">
                            <i>
                              <p v-html="producto.additionalinformation"></p> 
                            </i>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <v-card flat width="400" class="">
                          <v-card-title class="text-h5">
                            Instrucciones:
                          </v-card-title>
                          <v-card-text class="">
                            <i>
                              <p v-html="producto.usemode"></p> 
                            </i>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card>
                </v-col>
                <v-col v-if="caracteristicas">
                  <v-card elevation="5" max-width="80%" class="mx-auto" >
                    <v-card color="cyan" dark elevation="0" >
                      <v-card-subtitle  style="font-size:15px;color:white;"> COMENTARIOS DE OTROS USUARIOS</v-card-subtitle >
                    </v-card>
                    <v-card v-if="productoComentario == false" elevation="0">
                      <center>
                        <v-card-text style="font-size:20px; color:#C1C1C1;">No hay comentarios...</v-card-text>
                      </center>
                    </v-card>
                    <div  v-if="productoComentario != false">
                      <v-card style="margin-top:10px;" disabled elevation="0"   v-for="item in productoComentario" :key="item._id">
                        <v-row>
                          <v-col md="2" class="container">
                            <center>
                              <v-img class="imagen" style="" width="30px" src="../../public/usuario.png"></v-img>
                            </center>
                          </v-col>
                          <v-col md="7">
                            <div style="font-size">{{item.nombre}}</div>
                            <div>{{item.opinion}}</div> 
                            <v-divider class="mx-10" color="cyan"></v-divider>
                          </v-col>
                          <v-col>
                            <v-col md="3">
                              <v-rating
                                v-model="item.calificacion"
                                background-color="#6E6F64 lighten-4"
                                color="yellow"
                                half-increments
                                hover
                                size="20"
                                @input="panelIngresarComen = true"
                              ></v-rating>
                        </v-col>
                          </v-col>
                        </v-row>
                      </v-card>
                    </div>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn v-if="productoComentario != false && productoComentario.length >= 4" @click="totalComentarios(producto.value)" small text color="cyan">Ver más</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <!-- //Muestra todos los comentarios -->
                
                <v-dialog v-model="comentariosGenerales" max-width="80%" persistent>
                  <v-col md="">
                    <!-- <v-btn text color="cyan" @click="ocultarComentarios()"><v-icon style="font-size:18px;">mdi-arrow-left</v-icon>Volver</v-btn> -->
                    <v-card elevation="5"  class="mx-auto">
                    <v-card color="cyan" dark elevation="0" >
                        <v-card-subtitle  style="font-size:15px;color:white;"> OPINIONES DE OTROS USUARIOS</v-card-subtitle >
                    </v-card>
                    <v-card style="margin-top:10px;" disabled elevation="0" v-for="item in calificacionUser" :key="item._id">
                        <v-row>
                        <v-col md="2" class="container">
                            <center>
                            <v-img class="imagen" style="" width="30px" src="../../public/usuario.png"></v-img>
                            </center>
                        </v-col>
                        <v-col md="7">
                            <div style="font-size"><strong>{{item.nombre}}</strong> </div>
                            <div>{{item.opinion}}</div>    
                            <v-divider class="mx-10" color="cyan"></v-divider>
                        </v-col>
                        <v-col>
                            <v-col md="3">
                            <v-rating
                                v-model="item.calificacion"
                                background-color="#6E6F64 lighten-4"
                                color="yellow"
                                half-increments
                                hover
                                size="20"
                                @input="panelIngresarComen = true"
                            ></v-rating>
                        </v-col>
                        </v-col>
                        </v-row>
                    </v-card>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="comentariosGenerales=false;"  text color="error">Cerrar</v-btn>
                    </v-card-actions>
                    </v-card>
                  </v-col>
                </v-dialog>
                <v-dialog v-model="aviso" max-width="40%" transition="dialog-bottom-transition" persistent>
                  <v-card>
                    <div  style="background-color:#1988EA;color:white; height:30px;">
                      <div class="container">
                        <div class="text-h5" style="margin-top:-12px;">¡Aviso!</div> 
                      </div>
                    </div>
                    <div class="text-h6 container text-center"> ¡ Tú comentario lo podrás ver cuando sea aprobado !</div> 
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn dark small color="blue" @click="aviso = false">Aceptar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              
            </v-container>  
            <!-- <v-row class="my-5" v-if="caracteristicas"> 
              <v-container v-if="caracteristicas">
               
              </v-container> 
            </v-row> -->
            
        
              
           
          
          </v-row> 
          <v-row v-else no-gutters class="my-1 mx-auto">
              <v-container  style="min-height:556px;"  >
              <div >
                <v-card style="min-height:186px;"> 
                  <v-row style="margin:20px">
                    <v-col  cols="12" sm="6" md="2"> 
                      <!-- <v-img width="200px" :src="producto.img"></v-img> -->
                      <v-img width="200px" :src="`${ubicacionImagenProducto}${producto.value}.jpg`" :lazy-src="`../../public/noImg.png`"
                        aspect-ratio="1" class="grey lighten-2" > 
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="grey lighten-5" ></v-progress-circular>
                          </v-row>
                        </template> 
                      </v-img>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <div class="my-5" style="font-size: 1em;color :#00A6FF">  
                        <v-icon style=" color: green;">mdi-checkbox-marked-circle</v-icon> AGREGASTE A TU CARRITO 
                      </div>
                      <div  style="font-size: 1em;">  
                      {{ producto.name }}
                      </div>  
                    </v-col>
                    <v-col>
                        <br>
                        <v-card-actions>
                          <v-btn class="mx-auto white--text" width="100%" large color="primary" @click="menu('/shop/shopingcar/')" >
                            Ver carrito
                          </v-btn>  
                        </v-card-actions>

                        <v-card-actions>
                          <v-btn class="mx-auto white--text" width="100%" large color="success" @click="menu('/shop/')" >
                            Seguir comprando
                            <v-icon right dark>mdi-shopping</v-icon>
                          </v-btn>  
                        </v-card-actions>
                    </v-col>
                  </v-row> 
                </v-card> 
              </div>  
            </v-container>
          </v-row>  
        </div>
        
        <div v-if="isLoad == false && productoEncontrado==false" >
          <v-container class="mx-auto text-center" style="min-height:706px;">
            <br><br><br>
            <img width="280" src="../../public/error.gif">
            <p style="font-size: 2em;color :#909090">  
              <strong>El producto que buscas no esta disponible.</strong> 
            </p>
          </v-container>
        </div>
        <v-spacer class="my-12"></v-spacer>
      </v-col>
      <v-col cols="12" md="2" v-if="!isMobile() && verProductosNovedad == true" >
          <v-card  class="my-5 container" style="margin-right:10px" elevation="15" width="100%">
            <v-btn fab x-small color="pink" @click="verProductosNovedad=false" ><v-icon>mdi-close</v-icon></v-btn>
            <v-alert class="my-2 text-center">
                <h2 style="color:orange;">
                <strong>¡ NUEVOS PRODUCTOS !</strong>
              </h2> 
            </v-alert>
            <v-virtual-scroll item-height="350" height="1100" :items="productoNuevo">
                <template v-slot:default="{item}">
                  <center>
                    <v-alert width="100%" max-width="197" color="cyan" border="left"  elevation="3" colored-border @click="seeProduct(item.value)"> 
                      <a style="text-decoration: none;"> 
                        <div>
                          <center>   
                            <v-img width="80%" :src="`${ubicacionImagenProducto}${item.value}.jpg`" 
                                  :lazy-src="`../../public/noImg.png`"
                                    aspect-ratio="1" class="grey lighten-2"> 
                              <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                  <v-progress-circular indeterminate color="pink" ></v-progress-circular>
                                </v-row>
                              </template> 
                            </v-img>
                          </center>
                          <v-card-text>
                            <div v-snip="1" class="my-3 subtitle-2">{{item.name}}</div> 
                          </v-card-text>  
                        </div>
                      </a>
                    </v-alert>
                  </center>
                </template>  
            </v-virtual-scroll>
        </v-card> 
      </v-col>
      <v-col cols="9" md="2" v-if="isMobile() && verProductosNovedad == true" >
          <v-card  class="my-5 container" style="margin-right:10px" elevation="15" >
            <v-btn fab x-small color="pink" @click="verProductosNovedad=false" ><v-icon>mdi-close</v-icon></v-btn>
            <v-alert class="my-2 text-center">
              <h2 style="color:orange;">
                <strong>¡ NUEVOS PRODUCTOS !</strong>
              </h2> 
            </v-alert>
            <v-virtual-scroll item-height="350" height="1100" :items="productoNuevo">
              <template v-slot:default="{item}">
                <v-alert width="100%" color="cyan" border="left"  elevation="1" colored-border @click="seeProduct(item.value)"> 
                  <a style="text-decoration: none;"> 
                    <div>
                      <center>   
                        <v-img width="60%" :src="`${ubicacionImagenProducto}${item.value}.jpg`" 
                              :lazy-src="`../../public/noImg.png`"
                                aspect-ratio="1" class="grey lighten-2"> 
                          <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                              <v-progress-circular indeterminate color="pink" ></v-progress-circular>
                            </v-row>
                          </template> 
                        </v-img>
                      </center>
                      <v-card-text>
                        <div class="my-3 subtitle-2">{{item.name.substring(0,83)}}</div> 
                      </v-card-text>  
                    </div>
                  </a>
                </v-alert>
              </template>
            </v-virtual-scroll>
        </v-card> 
      </v-col>
      <v-row>
        <!-- //>>>>>>>>PRODUCTOS MÁS COMPRADOS -->
        <v-container v-if="caracteristicas">
          <v-col md="12">
            <v-card-title class="text-h6">Productos más comprados</v-card-title>
          </v-col>
          <v-row class="justify-center" v-if="!isMobile()">
            <template>
              <center>
                <v-sheet
                  color="grey lighten-5"
                  class="mx-auto"
                  elevation="0"
                  width="100%"
                  max-width="1110"
                >
                  <v-slide-group
                    v-model="model"
                    active-class="success"
                    center-active
                    show-arrows
                  > <v-slide-item  v-slot="{active,toggle}" v-for="productosC in masComprados" :key="productosC.value" style="width:200px;">
                      <v-col >
                        <v-card :color="active ? '#7FFFD4' : 'white'"  class="container" height="100%" @click="toggle"  elevation="8">
                            <v-img  @click="seeProduct(productosC.value)"  width="150px" :src="`${ubicacionImagenProducto}${productosC.value}.jpg`" :lazy-src="`../../public/noImg.png`"
                                      aspect-ratio="1" class="grey lighten-2"> 
                                <template v-slot:placeholder>
                                  <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-5" ></v-progress-circular>
                                  </v-row>
                                </template> 
                              </v-img> 
                              <div style="font-size:12px;" class="container">
                                <v-divider color="orange" style="margin-top:10px"></v-divider>
                                <br>
                                {{productosC.name}}
                              </div>
                        </v-card>
                      </v-col>
                  </v-slide-item>
                    
                  </v-slide-group>
                </v-sheet>
              </center>
            </template>
          </v-row>
          <v-row class="justify-center" v-if="isMobile()">
            <template>
              <center>
                <v-sheet
                  color="grey lighten-5"
                  class="mx-auto"
                  elevation="0"
                  width="100%"
                  max-width="305"
                >
                  <v-slide-group
                    v-model="model"
                    active-class="success"
                    center-active
                    show-arrows
                  > <v-slide-item  v-slot="{active,toggle}" v-for="productosC in masComprados" :key="productosC.value" style="width:200px;">
                      <v-col >
                        <v-card :color="active ? '#7FFFD4' : 'white'"  class="container" height="100%" @click="toggle"  elevation="8"> 
                            <v-img  @click="seeProduct(productosC.value)"  width="150px" :src="`${ubicacionImagenProducto}${productosC.value}.jpg`" :lazy-src="`../../public/noImg.png`"
                                      aspect-ratio="1" class="grey lighten-2"> 
                                <template v-slot:placeholder>
                                  <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-5" ></v-progress-circular>
                                  </v-row>
                                </template> 
                              </v-img> 
                              <div style="font-size:12px;" class="container">
                                <v-divider color="orange" style="margin-top:10px"></v-divider>
                                <br>
                                {{productosC.name}}
                              </div>
                        </v-card>
                      </v-col>
                  </v-slide-item>
                    
                  </v-slide-group>
                </v-sheet>
              </center>
            </template>
          </v-row>
          <br>
        </v-container> 
      </v-row>
    </v-row>
  </v-container>
  </div>
</template>


<style scoped>
  .centered-input >>> input {
    text-align: center
  }
    .producto_principal
  {
    font-style: italic;
    font-family: "New Century Schoolbook", Times, serif;
    font-weight: bold;
  }
  .producto_especificaciones
  {
    font-style: italic;
    font-family: "New Century Schoolbook", Times, serif;
  }
  .producto_descripcion_general {
    font-family: 'Book Antiqua';
  }
  .ratingUser{
    font-family:monospace;
    /* margin-left: ;  */

  }
  .ratingCursor{
    
    cursor: not-allowed;

    pointer-events:none;
    
  }

</style>

<script>
import axios from 'axios'; 
import config from '../json/config.json'
import AppMenu from '../components/Menu.vue';

export default {
  name:"appProducto",
  props: ['value','origen','pagina'], 
  data() {
    return { 
      qty : 1
      ,ubicacionImagenProducto:'https://amoresens.com.mx/distribuidor/productos/'
      ,producto : {value:0}
      ,user:{}
      ,isLogged:false
      ,isLoad:false
      ,agregado : false
      ,productoEncontrado: false
      ,msgErro:''
      ,productosOferta : []
      ,cpdePromo :['P15UN0978','P15UN0979','P15UN0851','P15UN0905']
      ,codigoProd : ""
      ,tabs: null
      ,itemstabs: [
        'Carácteristicas Técnicas', 'Información Adicional', 'Modo Uso',
      ],
      masComprados:[], //Productos más comprados
      model:null,
      calificacionProducto:[],
      addCalificacion:{},
      calificacionUser:{},
      usuario:[],
      productos:{},
      productoYaCalificado:false,
      validar:{},
      panelCoemntarios:false,
      panelIngresarComen:false,
      totalT:{},
      rating:{},
      productoComentario:{},
      comentariosGenerales:false,
      caracteristicas:true,
      existeComentario:{},
      existeComent:false,
      msgWarning:"",
      alert2:false,
      aviso:false,
      verProductosNovedad:true,
      productoNuevo:[]
    }
  },
  components: { 
      'app-menu': AppMenu, 
      // VueStar
  }, 
  created(){ 
    // this.calificacion()
    this.productosMasComprados();
    this.codigoProd = this.value; 
    window.scrollTo(0,0); 
    this.iniciaDatos();
    
  },
  mounted(){ 
  this.ProductosNuevos();
   },
  methods:{
    isMobile(){
      if( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)){
          return true;
        }else{
          return false;
        }
    },
    returnOrigen(){
        if (this.origen == "home") { 
          let regresarParametros = this.$route.query;
          regresarParametros.isValid = true;
          // this.$router.push({path:`/shop/${this.origen}/${this.pagina}` ,query: regresarParametros});
          this.menu("/shop/Home/");
          // this.menu("/shop/Home/"+this.pagina);
        }else{
          this.menu("/shop/"+this.origen+'/');
        }
    },
    async cantidadCar(){
        await axios.get(config.apiAmoresens + "/shopingcar/get_auth", 
        {
          'headers': { 'token': this.$cookie.get('token') }
        }).then(res=>{
          if(res.data.status == "success"){ 
            document.getElementById("cantidadInCar").value = res.data.data.length;
            return res.data; 
          }  
        })
        .catch(err=>{return err;});   
    },
    menu(path){
        if (this.$route.path !== path){
          this.$router.push(path);
        }  
    }, 
    seeProduct(value){  
      this.$router.push('/shop/Product/'+value+"/home/1");
      window.location.reload();
      // this.rating.calificacion = "";
      // this.totalT = "";
      // this.calificacionUser = ""
      this.codigoProd = value;
      this.iniciaDatos();
    }
    
    ,async iniciaDatos(){ 
      this.isLoad = true;
      await this.validaLogin(); 
      await this.getProduct(); 
      this.productosOferta = [];
      for (let index = 0; index < this.cpdePromo.length; index++) {
        let responce = await this.getProductByCode(this.cpdePromo[index]); 

        if(responce == 'undefined' || responce == undefined){
          responce = [];
        }else{
          try {
            if (responce.value != undefined && responce.value != this.codigoProd) {
              this.productosOferta.push(responce);
            }
          } catch (error) {
            console.log(error);
          }
        }

      }
      this.isLoad = false;
      
    }
    
    ,async addtocar(){  
        this.isLoad = true;
        if (this.isLogged) {
          if (this.isInt(this.qty) && this.qty > 0) { 
            if (this.qty > this.producto.total) {
              if (this.producto.total == 1) {
                this.msgErro = "El límite de compra es 1 pza.";
              }else{
                this.msgErro = "El límite de compra son " + this.producto.total + " pzas.";
              } 
              this.isLoad = false; 
              return;
            }
            this.agregado = await axios.post(config.apiAmoresens + "/shopingcar/add_auth", 
            {value: this.producto.value, cantidad : this.qty},{headers:{'token': this.$cookie.get('token')}})
            .then(res=>{ 
              if(res.data.status == "success"){
                return true;
              }else{
                console.log(res.data.status);
                return false;
              } 
            }).catch(err=>{
              console.log(err);
              return false;
            });  
            if (this.agregado) { 
              this.cantidadCar();
            }
            this.msgErro = '';
            window.scrollTo(0,0);  
          }else{
            this.msgErro = "Ingresa una cantidad válida."
          }
        } else {
            this.menu('/shop/Login/');
        }
        this.isLoad = false; 
    },
    isInt(value) {
        var x;
        return isNaN(value) ? !1 : (x = parseFloat(value), (0 | x) === x);
    },
    async qtyMovement(vl){
        try { 
          if (this.isInt(this.qty)) { 
            if (vl == '+') {
              // if (this.qty >= this.producto.total) {
              //   this.qty = this.producto.total; 
              // }else{
                
              // } 
              this.qty  = parseInt(this.qty) + 1;
            }else  if (vl == '-') {
              if ( parseInt(this.qty)  > 1) {
                  this.qty =  parseInt(this.qty)  - 1;
              }
            }
            if (this.qty < 0) {
              this.qty = this.qty * -1;
            }
            // if (this.qty >= this.producto.total) {
            //     this.qty = this.producto.total; 
            // }
          }else{
            if (this.qty != "") {
              this.qty = 0;
            }
          }  
        } catch (error) { 
          console.log(error);
          this.qty = 1;
        }  
    },
    async getProduct(){
      try {
        let uri = config.apiAmoresens + "/productos/all";
        this.producto = await axios.get(uri
        ,{headers: { 'token': this.$cookie.get('token') },
        params: {
            filter: this.codigoProd
            ,onliStock : 0
            ,range : [0]
            ,andalucia : '0' 
            ,ld : '0' 
            ,ordenMenorP : '0' 
            ,ordenMayorP : '0' 
            ,ordenMasVendido : '0' 
          }}
        ).then(function (response) { 
          return response.data;
        }).catch(function (response){  
          return response;
        });   
        if (this.producto.status == "success") {
            this.producto = this.producto.data;  
            
          if (this.producto.length > 0) {
              this.producto = this.producto[0];  
            this.productoEncontrado = true; 
             
          }  
        } else {
          this.productoEncontrado = false; 
        } 
      } catch (error) {
        this.productoEncontrado = false; 
      }
    },
    async getProductByCode(value){
      try {
        let uri = config.apiAmoresens + "/productos/all";
        const producto = await axios.get(uri
        ,{
          headers: { 'token': this.$cookie.get('token') },
          params: {filter: value,onliStock : 0,range : [0],andalucia : '0',ld : '0',ordenMenorP : '0',ordenMayorP : '0',ordenMasVendido : '0' }
        }).then(function (response) { 
          
          return response.data;
          
        }).catch(function (response){  
          return response;
        });  
        // console.log(this.producto);
        this.calificacionUsuario(this.producto)
        this.comentariosProducto(this.producto)
       
        if (producto.status == "success") { 
            return producto.data[0];
        } else {
          return [];
        } 
      } catch (error) {
        return false;
      }
    },
    async validaLogin(){
      this.user = await axios.get(config.apiAmoresens + "/user/userByToken", 
                  {'headers': { 'token': this.$cookie.get('token') }})
                  .then(res=>{return res.data;})
                  .catch(err=>{return err;});    
      if (this.user.status == "success") {
        this.user = this.user.user; 
        this.isLogged = true;

      }else if(this.user.status == "unauthorized"){ 
        this.isLogged = false; 
        let inputCar = document.getElementById("cantidadInCar");
        if (inputCar != null) {
          inputCar.value = "";
        }
      }
    },
    formatMXN(value) {
          var formatter = new Intl.NumberFormat('en-ES', {style: 'currency', currency: 'USD',});
          return formatter.format(value);
    },
    async productosMasComprados(){
      await axios.get(config.apiAmoresens + '/productos/productosmasComprados')
      .then(res=>{
        this.masComprados = res.data.data;
        // console.log(this.masComprados);
      }).catch(err=>{
        console.log(err);
      })
    },
    // async calificacion() {
    //   await axios.get(config.apiAmoresens + '/cal/obtenerCalificacion',
    //   {'headers': { 'token': this.$cookie.get('token') }}
    //   ).then(res=>{
    //     this.calificacionProducto = res.data.data;
    //     console.log(this.calificacionProducto, 'calificacion general');
    //   }).catch(err=>{
    //     return err
    //   })
    // },
    async agregCalificacion(){
      // this.addCalificacion.calificacion = this.rating.calificacion;
      this.addCalificacion.value = this.producto.value;
      this.addCalificacion.m_product_id = this.producto.m_product_id;
      this.addCalificacion.ad_user_id = this.user.ad_user_id;
      this.addCalificacion.name = this.user.username;
      // console.log(this.addCalificacion);
      await axios.post(config.apiAmoresens + '/cal/agregarCalificacion',this.addCalificacion,
      {'headers': { 'token': this.$cookie.get('token') }}
      ).then(res=>{
        console.log(res.data);
        
      }).catch(err=>{
        console.log(err);
      })
      this.iniciaDatos();
      this.addCalificacion.opinion="";
      this.addCalificacion.calificacion="";
      this.panelIngresarComen = false;
      this.aviso = true;
      
    },
    async comentariosProducto(){ // comentarios en general con limite de 4
      this.productoComentario = await axios.get(config.apiAmoresens + '/cal/promedioCalificaciones',
      {'headers': { 'token': this.$cookie.get('token') }
        ,params: {value:this.producto.value}}
      ).then(res=>{
         return res.data;
      }).catch(err=>{
        return err, false
      })
      if(this.productoComentario == false){  
            // console.log('no hay registros');
      }else if(this.productoComentario.status == 'success'){
        this.productoComentario = this.productoComentario.data;
        // console.log(this.productoComentario);
      }
      // else if(this.productoComentario.status == "error"){  
      //       // this.menu('/shop/Login/');
      //       console.log('no hay registros');
      //   }
    },
    async calificacionUsuario(){ //Saca el promedio de las estrellas calificadas
      this.validar  = await axios.get(config.apiAmoresens + '/cal/opiniones',
      {'headers': { 'token': this.$cookie.get('token') }
        ,params: {value:this.producto.value}}
      ).then(res=>{
         return res.data;
      }).catch(err=>{
        return err
      })
      // if(this.validar == '') {  
      //       console.log('errorrrr');
      //   }
      if(this.validar.status == 'success'){
        this.calificacionUser = this.validar.data;
        let suma = 0
        for (let index = 0; index < this.calificacionUser.length; index++) {
          const element = this.calificacionUser[index].calificacion;
          // console.log(element,'calificaciones');
          suma += element;
          // console.log(suma);
          
        }
        const usuarios = this.calificacionUser.length
        // console.log(usuarios,'total'); 
        this.totalT = suma/usuarios
        // console.log(suma,'suma');
        console.log(this.totalT,'promedio');
        if (this.totalT >=0 && this.calificacionUser.length >= 0) {
            this.rating.calificacion = this.totalT
        }
        // console.log('no hay datos');
      }
      else if(this.validar.status == 'error') {  
          // console.log('errorrrr');
      }
    },
    totalComentarios(){
      this.comentariosGenerales=true;
      // this.caracteristicas = false;

      // console.log(value);
      // this.menu(`/shop/comentarios/${value}`);
    },
    // ocultarComentarios(){
    //   this.comentariosGenerales=false;
    //   this.caracteristicas=true;
    // },
    async calificar(ad_user_id, value){
      console.log(ad_user_id, value);
      await this.validaLogin();
      if (this.isLogged) {
        await this.validarComentario(ad_user_id,value);
        if(this.existeComent){
          // console.log('YA EXISTE COMENTARIO');
          this.alert2 = true;
          this.msgWarning='Ya existe un registro tuyo.'
          window.scrollTo(0,0);
        }else{
          this.panelIngresarComen = true;
          this.alert2 = false;
        }
        
      }else{
        this.menu('/shop/Login/');
      }
    },
    async validarComentario(ad_user_id,value){
      // console.log(ad_user_id);
      await axios.get(config.apiAmoresens + '/cal/validacionComentario',
      {params:{ad_user_id:ad_user_id,value:value}})
      .then(res=>{
        this.existeComentario = res.data;
        // console.log(this.existeComentario);
      }).catch(err=>{return err;})
      if(this.existeComentario.status == "success"){
        this.existeComentario = this.existeComentario.data;
        return this.existeComent = true;
      }else{
        if(this.existeComentario.status == "error"){
          // console.log('error');
          return this.existeComent = false;
        }
      }
    },
    async ProductosNuevos(){ //Productos nuevos
      await axios.get(config.apiAmoresens + '/productos/ProductosNuevos')
      .then(respuesta=>{
        this.productoNuevo = respuesta.data.data;
      }).catch(err=>{return err;})
    }
    
  },
  computed:{  
    calculaTotalProd(){
      let total = 0;
        total = this.producto.l0 * this.qty;
      return this.formatMXN(total);
    },
  },

}
</script>